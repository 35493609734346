import React, { useEffect, useMemo, useState } from 'react'

import Papa from 'papaparse'

import { useMsal } from '@azure/msal-react'
import { useTheme } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  CenteredDiv,
  CustomDataGrid,
  downloadBlob,
  ErrorMessage,
  HasAccess,
  PersistantFilterDiv,
  RegularButton,
  useWtxLocalization
} from '@wavetronix/common-components'

import { env } from '../../index.js'
import UserDrawer from '../modals/UserDrawer'
import GatekeeperApi from '../../api/GatekeeperApi'
import { filterUsers } from '../drawers/FilterDrawer'
import UserSalesDrawer from '../modals/UserSalesDrawer'
import { getCountryHash } from '../../utils/countryUtil.js'
import FilterDrawer, { DEFAULT_FILTER } from '../drawers/FilterDrawer'

export default function UsersPage() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const { instance, accounts } = useMsal()
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [pageSize, setPageSize] = useState(100)
  const [userDrawerState, setUserDrawerState] = useState({
    open: false,
    user: null,
    groups: []
  })
  const [filteredUserData, setFilteredUserData] = useState({})
  let localizedStrings = useWtxLocalization()

  document.title = 'Nexus Management'

  //TODO: GetUsers with injected Roles??
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['users'],
    queryFn: async () => await GatekeeperApi.getUsersIncludeDeactivated(instance, accounts)
  })
  const { data: groupData } = useQuery({
    queryKey: ['Groups'],
    queryFn: async () => await GatekeeperApi.getGroups(instance, accounts)
  })
  const { data: regionData } = useQuery({
    queryKey: ['regions'],
    queryFn: async () => await GatekeeperApi.getRegions(instance, accounts)
  })

  const usersGroupMap = useMemo(() => {
    if (data && groupData) {
      //for each user in data, set id as key and then make a list of all roles from groups
      var res = data.reduce((map, value) => {
        map[value.id] = groupData.reduce((list, group) => {
          if (value.groups.includes(group.id)) {
            list = [...list, ...group.roles]
          }
          return list
        }, [])

        return map
      }, {})

      return res
    }
  }, [data, groupData])

  useEffect(() => {
    if (filter && data && usersGroupMap) {
      setFilteredUserData(filterUsers(filter, data, usersGroupMap))
    }
  }, [setFilteredUserData, filter, data, usersGroupMap])

  const groupMap = useMemo(() => {
    if (groupData) {
      let result = {}
      for (let group of groupData.filter(g => g.fromCompany === env.basicAuthentication.fromCompany)) {
        result[group.id] = group.groupName
      }
      return result
    }
  }, [groupData])

  const columns = [
    {
      field: 'givenName',
      headerName: localizedStrings.name,
      flex: 1,
      disableColumnMenu: largeScreenFeaturesActive,
      sortable: true,
      filterable: false,
      valueGetter: u => `${u.row.givenName} ${u.row.surname}`
    },
    {
      field: 'company',
      headerName: localizedStrings.company,
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      hide: isSmallScreen
    },
    {
      field: 'email',
      headerName: localizedStrings.email,
      flex: 1,
      disableColumnMenu: largeScreenFeaturesActive,
      filterable: false,
      sortable: true
    },
    {
      field: 'groups',
      headerName: localizedStrings.groups,
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      hide: isSmallScreen,
      valueGetter: u =>
        u.row.groups && groupMap
          ? u.row.groups
              .filter(group => groupMap[group])
              .map(group => groupMap[group])
              .join(', ')
          : []
    },
    {
      field: 'country',
      headerName: localizedStrings.region,
      flex: 0.5,
      disableColumnMenu: largeScreenFeaturesActive,
      filterable: false,
      sortable: true,
      renderCell: data => {
        return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{getCountryHash(data.row.country)}</div>
      }
    }
  ]

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  function GetGroups(user) {
    return user.groups && groupMap
      ? user.groups
          .filter(group => groupMap[group])
          .map(group => groupMap[group])
          .join(', ')
      : []
  }

  return (
    <PersistantFilterDiv
      defaultOpen={largeScreenFeaturesActive}
      resetFilter={() => setFilter(DEFAULT_FILTER)}
      drawer={
        <FilterDrawer setFilter={setFilter} filter={filter} groups={groupData} countries={regionData} hasDeactivated={true} />
      }
      page={
        <>
          <RegularButton
            id='exportUsersCSVButton'
            style={{ marginLeft: '24px', marginBottom: '10px' }}
            onClick={() => {
              if (filteredUserData) {
                let transformedData = filteredUserData.map(user => ({
                  'First Name': user.givenName,
                  'Last Name': user.surname,
                  Company: user.company,
                  'E-Mail': user.email,
                  Location: user.country,
                  'Activation Status': user.activationStatus,
                  Groups: `${GetGroups(user)}`
                }))
                let csvData = Papa.unparse(JSON.stringify(transformedData))
                downloadBlob(csvData, 'user_data.csv', 'text/csv;charset=utf-8;')
              }
            }}
          >
            Export Users
          </RegularButton>
          <CustomDataGrid
            rows={data ? filterUsers(filter, data, usersGroupMap) : []}
            autoHeight
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={p => setPageSize(p)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            disableSelectionOnClick
            loading={isLoading}
            style={{ margin: '0px 0px 0px 24px' }}
            onRowClick={async e =>
              setUserDrawerState({
                open: true,
                user: e.row,
                groups: await GatekeeperApi.getUserGroups(instance, accounts, e.row.id)
              })
            }
            cursor='pointer'
          />

          <HasAccess
            env={env}
            allowedRoles={['Gatekeeper Admin']}
            unauthorizedControl={
              <UserSalesDrawer
                open={userDrawerState.open}
                onClose={() => {
                  setUserDrawerState({ open: false, user: null, groups: [] })
                }}
                refetch={refetch}
                user={userDrawerState.user}
                groups={userDrawerState.groups}
              />
            }
          >
            <UserDrawer
              open={userDrawerState.open}
              onClose={() => {
                setUserDrawerState({ open: false, user: null, groups: [] })
              }}
              refetch={refetch}
              user={userDrawerState.user}
              groups={userDrawerState.groups}
              groupData={groupData}
            />
          </HasAccess>
        </>
      }
    />
  )
}
