import { useMsal } from '@azure/msal-react'
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  Chip
} from '@mui/material'
import {
  CenteredDiv,
  CustomAccordion,
  DeleteButton,
  HasAccess,
  PersistantFilterDiv,
  SlideUpDialog,
  SnackbarVariants,
  SuccessButton,
  WtxColors,
  useWtxLocalization
} from '@wavetronix/common-components'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'
import React, { Suspense, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import DocumentsApi from '../../api/DocumentsApi'
import GatekeeperApi from '../../api/GatekeeperApi'
import LicensesApi from '../../api/LicensesApi'
import UpdatesApi from '../../api/UpdatesApi'
import { env } from '../../index.js'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import RoleFilterDrawer, { DEFAULT_ROLE_FILTER } from '../drawers/RoleFilterDrawer'
import UserEmailNotificationsTable from '../gridComponents/UserEmailNotificationsTable'
import UserRolesTable from '../gridComponents/UserRolesTable'
import VerifyDeleteModal from './VerifyDeleteModal'
import ItemTimeline from '../gridComponents/ItemTimeline'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    color: 'black'
  }
}

const URL_MAP = {
  roles: 0,
  notifications: 1,
  history: 2,
  '': 0
}

export const COMPANY_TAGS = [
  { value: 'Wavetronix', id: 'wavetronix' },
  { value: 'Island Radar', id: 'islandradar' },
  { value: 'Fathom', id: 'fathom' }
]

export default function UserDrawer(props) {
  const { instance, accounts } = useMsal()
  const [widgetsDisabled, setWidgetsDisabled] = useState(false)
  const [groups, setGroups] = useState([])
  const [tags, setTags] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [state, setState] = useState('roles')
  let localizedStrings = useWtxLocalization()
  const [roleMap, setRoleMap] = useState({})
  const [activationStatus, setActivationStatus] = useState('')
  const [rolesList, setRolesList] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [filter, setFilter] = useState(DEFAULT_ROLE_FILTER)
  const [deleteModelOpen, setDeleteModelOpen] = useState(false)
  const [groupsUserIsNotIn, setGroupsUserIsNotIn] = useState([])

  const { data: rolesData, isLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => await GatekeeperApi.getRoles(instance, accounts)
  })
  const { data: userData, refetch: userRefetch } = useQuery({
    queryKey: ['user', selectedUser],
    queryFn: async () => await GatekeeperApi.getUserById(instance, accounts, selectedUser.id),
    enabled: selectedUser !== null
  })
  const { data: userDataNoGroups, refetch: userNoGroupsRefetch } = useQuery({
    queryKey: ['userNoGroup', selectedUser],
    queryFn: async () => await GatekeeperApi.getUserByIdNoGroups(instance, accounts, selectedUser.id),
    enabled: selectedUser !== null
  })

  useEffect(() => {
    if (userData && userData.activationStatus) {
      setActivationStatus(userData.activationStatus)
    }
  }, [userData])

  useEffect(() => {
    if (rolesData && userData && userDataNoGroups) {
      rolesData.forEach(role => {
        var hasRole = 'none'

        //userData has all roles
        //props.user doesn't have group roles
        if (userData.roles && userDataNoGroups.roles) {
          if (userData.roles.includes(role.id)) {
            if (userDataNoGroups.roles.includes(role.id)) {
              hasRole = 'user'
            } else {
              hasRole = 'group'
            }
          }
        }
        setRoleMap(rm => ({ ...rm, [role.id]: hasRole }))
      })
    }
  }, [rolesData, userData, userDataNoGroups])

  useEffect(() => {
    if (props.user) {
      setSelectedUser(props.user)
    }
  }, [props.user])

  useEffect(() => {
    if (rolesData) {
      setRolesList(rolesData)
    }
  }, [rolesData])
  const onSwitchChange = async (e, role) => {
    setWidgetsDisabled(true)

    if (e.target.checked === true) {
      await GatekeeperApi.addRoleToUser(instance, accounts, selectedUser.id, role)
        .then(async () => {
          await Promise.all([await userRefetch(), await userNoGroupsRefetch()])
          enqueueSnackbar(localizedStrings.snackbar.savedRoles, SnackbarVariants.SUCCESS)
        })
        .catch(e => {
          enqueueSnackbar(localizedStrings.snackbar.errorSaving, SnackbarVariants.ERROR)
        })
        .finally(() => setWidgetsDisabled(false))
    } else {
      await GatekeeperApi.removeRoleFromUser(instance, accounts, selectedUser.id, role)
        .then(async () => {
          await Promise.all([await userRefetch(), await userNoGroupsRefetch()])
          enqueueSnackbar(localizedStrings.snackbar.savedRoles, SnackbarVariants.SUCCESS)
        })
        .catch(e => {
          enqueueSnackbar(localizedStrings.snackbar.errorSaving, SnackbarVariants.ERROR)
        })
        .finally(() => setWidgetsDisabled(false))
    }
  }

  const onClose = async () => {
    setSelectedUser(null)
    if (!widgetsDisabled) {
      props.onClose()
    }
    setGroups([])
    await props.refetch()
  }

  const deleteUser = async userId => await GatekeeperApi.deleteExternalUserById(instance, accounts, userId)

  const clearUpdatesFromUser = async userId => await UpdatesApi.removeUpdateOverridesByUserId(instance, accounts, userId)

  const clearDocumentsFromUser = async userId => await DocumentsApi.removeDocOverridesByUserId(instance, accounts, userId)

  const deleteLicensesFromUser = async userId => await LicensesApi.deleteLicensesByUserId(instance, accounts, userId)
  const deleteLicensesV2FromUser = async userId => await LicensesApi.deleteLicensesV2ByUserId(instance, accounts, userId)

  useEffect(() => {
    if (!props.groupData) {
      return
    }
    if (!userData) {
      return
    }

    let includedGroups = []
    let exclusionaryGroups = []

    for (let group of props.groupData) {
      if (userData.groups.includes(group.id)) {
        includedGroups.push(group)
      } else {
        exclusionaryGroups.push(group)
      }
    }

    setGroups(includedGroups)
    setGroupsUserIsNotIn(exclusionaryGroups)
    setTags([...userData.tags])
  }, [props.groupData, userData])

  async function addGroup(groupId) {
    let key = enqueueSnackbar('Adding to group....', SnackbarVariants.LOADING)
    await GatekeeperApi.addGroupToUser(instance, accounts, selectedUser.id, groupId)
      .then(async () => {
        closeSnackbar(key)
        userRefetch()
        enqueueSnackbar('Successfully added to group', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred when adding to group', SnackbarVariants.ERROR)
      })
  }

  async function removeGroup(groupId) {
    let key = enqueueSnackbar('Removing from group...', SnackbarVariants.LOADING)
    await GatekeeperApi.removeGroupFromUser(instance, accounts, selectedUser.id, groupId)
      .then(async () => {
        closeSnackbar(key)
        userRefetch()
        enqueueSnackbar('Successfully removed from group', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred when removing from group', SnackbarVariants.ERROR)
      })
  }

  async function tagChipClick(tag, tagExists) {
    if (tagExists) {
      //remove
      let key = enqueueSnackbar('Removing tag from group...', SnackbarVariants.LOADING)
      await GatekeeperApi.removeTagFromUser(instance, accounts, selectedUser.id, tag.id)
        .then(async () => {
          closeSnackbar(key)
          userRefetch()
          enqueueSnackbar(`Successfully removed ${tag.value} tag`, SnackbarVariants.SUCCESS)
        })
        .catch(() => {
          closeSnackbar(key)
          enqueueSnackbar(`An error occurred when removing ${tag.value} tag`, SnackbarVariants.ERROR)
        })
    } else {
      //add
      let key = enqueueSnackbar('Adding tag to group...', SnackbarVariants.LOADING)
      await GatekeeperApi.addTagToUser(instance, accounts, selectedUser.id, tag.id)
        .then(async () => {
          closeSnackbar(key)
          userRefetch()
          enqueueSnackbar(`Successfully added ${tag.value} tag`, SnackbarVariants.SUCCESS)
        })
        .catch(() => {
          closeSnackbar(key)
          enqueueSnackbar(`An error occurred when adding ${tag.value} tag`, SnackbarVariants.ERROR)
        })
    }
  }

  async function RunCleanup() {
    if (env.basicAuthentication.fromCompany === 'wavetronix') {
      return await Promise.all([
        clearUpdatesFromUser(selectedUser.id),
        clearDocumentsFromUser(selectedUser.id),
        deleteLicensesFromUser(selectedUser.id),
        deleteLicensesV2FromUser(selectedUser.id)
      ])
    } else {
      return await Promise.all([clearUpdatesFromUser(selectedUser.id), clearDocumentsFromUser(selectedUser.id)])
    }
  }

  return (
    <>
      <VerifyDeleteModal
        title={'Delete User'}
        open={deleteModelOpen}
        buttonsDisabled={widgetsDisabled}
        onClose={() => setDeleteModelOpen(false)}
        onDelete={async () => {
          setWidgetsDisabled(true)
          let cleaningKey = enqueueSnackbar('Cleaning user artifacts...', SnackbarVariants.LOADING)
          await Promise.all([RunCleanup()])
            .then(async s => {
              closeSnackbar(cleaningKey)
              enqueueSnackbar(
                `Removal of ${
                  env.basicAuthentication.fromCompany === 'wavetronix' ? 'licenses, documents,' : 'documents'
                } and updates for ${selectedUser.givenName} ${selectedUser.surname} userId: ${selectedUser.id} was successful`,
                SnackbarVariants.SUCCESS
              )
              let removingKey = enqueueSnackbar('Deleting user...', SnackbarVariants.LOADING)
              await deleteUser(selectedUser.id)
                .then(t => {
                  closeSnackbar(removingKey)
                  enqueueSnackbar(
                    `Removal of ${selectedUser.givenName} ${selectedUser.surname} userId: ${selectedUser.id} was SUCCESSFUL`,
                    SnackbarVariants.SUCCESS
                  )
                })
                .catch(e => {
                  closeSnackbar(removingKey)
                  enqueueSnackbar(
                    `Deletion of ${selectedUser.givenName} ${selectedUser.surname} userId: ${selectedUser.id} was unsuccessful`,
                    SnackbarVariants.ERROR
                  )
                })
            })
            .catch(e => {
              closeSnackbar(cleaningKey)
              enqueueSnackbar(
                `Removal of licenses, documents, and updates for ${selectedUser.givenName} ${selectedUser.surname} userId: ${selectedUser.id} was unsuccessful`,
                SnackbarVariants.ERROR
              )
            })
          setDeleteModelOpen(false)
          setWidgetsDisabled(false)
          onClose()
        }}
        message={
          <div>
            <p>Do you want to delete this user?</p> This means removing the user from Azure Active Directory and the Gatekeeper
            DB. All information about this user will be deleted. <p>This action is permanent and cannot be undone.</p>
          </div>
        }
      />
      <SlideUpDialog
        id='userDrawer'
        fullScreen
        buttonsDisabled={widgetsDisabled}
        open={props.open}
        onClose={onClose}
        title={<h3 style={{ marginTop: '0px' }}>{localizedStrings.manageUser}</h3>}
      >
        <PersistantFilterDiv
          defaultOpen={false}
          drawer={
            {
              roles: <RoleFilterDrawer setFilter={setFilter} filter={filter} />,
              notifications: <CenteredDiv>No filtering available</CenteredDiv>,
              '': <CenteredDiv>Tab Error: refresh page</CenteredDiv>
            }[state]
          }
          resetFilter={
            {
              roles: () => setFilter(DEFAULT_ROLE_FILTER),
              notifications: () => console.log('No filtering available'),
              '': () => console.log('Tab Error: refresh page')
            }[state]
          }
          page={
            <>
              <Grid container>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <Card sx={{ margin: '15px' }}>
                    <CardContent>
                      <TextField
                        id='userNameTextField'
                        disabled
                        style={{ width: '90%', marginTop: 30, marginLeft: 15 }}
                        label={'Name'}
                        size='small'
                        value={selectedUser ? `${selectedUser.givenName} ${selectedUser.surname}` : ''}
                        variant='outlined'
                      />
                      <TextField
                        id='userCompanyTextField'
                        disabled
                        style={{ width: '90%', marginTop: 15, marginLeft: 15 }}
                        label={'Company'}
                        size='small'
                        value={selectedUser ? `${selectedUser.company}` : ''}
                        variant='outlined'
                      />
                      <TextField
                        id='userEmailTextField'
                        disabled
                        style={{ width: '90%', marginTop: 15, marginLeft: 15 }}
                        label={'Email'}
                        size='small'
                        value={selectedUser ? `${selectedUser.email}` : ''}
                        variant='outlined'
                      />
                      {tags ? (
                        <>
                          <CardHeader subheader='Company Tags' style={{ textAlign: 'center' }} />
                          <Card variant='outlined' sx={{ padding: '15px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {COMPANY_TAGS.map(tag => (
                                <Chip
                                  id={`${tag.id}Chip`}
                                  key={tag.id}
                                  label={tag.value}
                                  sx={{
                                    margin: '2px',
                                    color: tags.includes(tag.id) ? 'white' : 'black',
                                    backgroundColor: tags.includes(tag.id) ? WtxColors.IQ_BLUE : 'white'
                                  }}
                                  onClick={async () => tagChipClick(tag, tags.includes(tag.id))}
                                />
                              ))}
                            </Box>
                          </Card>
                        </>
                      ) : null}
                      <CardHeader subheader='Groups' style={{ textAlign: 'center' }} />
                      {groups ? (
                        <CustomAccordion
                          defaultExpanded
                          title='Member'
                          style={{ width: '100%' }}
                          children={
                            <List>
                              {groups.map(group => {
                                return (
                                  <ListItem disablePadding key={group.id}>
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div
                                        id={`${group.id}OpenNewTabLink`}
                                        style={{ marginLeft: '15px', cursor: 'pointer' }}
                                        onClick={() => {
                                          let queryString = new URLSearchParams({
                                            id: group.id,
                                            isDealerGroup: group.dealerGroup
                                          }).toString()

                                          window.open(`/group/overview?${queryString}`, '_blank')
                                        }}
                                      >
                                        <div>{group.groupName} </div>
                                      </div>
                                      <Box sx={{ flex: '1 1 auto' }} />
                                      <IconButton
                                        id={`${group.id}RemoveFromButton`}
                                        onClick={() => removeGroup(group.id)}
                                        sx={{ justifyContent: 'flex-end', marginRight: '8px' }}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </Box>
                                  </ListItem>
                                )
                              })}
                            </List>
                          }
                        />
                      ) : (
                        <span />
                      )}
                      {groupsUserIsNotIn ? (
                        <CustomAccordion
                          title='Available'
                          style={{ marginTop: '15px', width: '100%' }}
                          children={
                            <List>
                              {groupsUserIsNotIn.map(group => {
                                return (
                                  <ListItem disablePadding key={group.id}>
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div
                                        id={`${group.id}OpenNewTabLink`}
                                        style={{ marginLeft: '15px', cursor: 'pointer' }}
                                        onClick={() => {
                                          let queryString = new URLSearchParams({
                                            id: group.id,
                                            isDealerGroup: group.dealerGroup
                                          }).toString()

                                          window.open(`/group/overview?${queryString}`, '_blank')
                                        }}
                                      >
                                        <div>{group.groupName} </div>
                                      </div>
                                      <Box sx={{ flex: '1 1 auto' }} />
                                      <IconButton
                                        id={`${group.id}AddFromButton`}
                                        onClick={() => addGroup(group.id)}
                                        sx={{ justifyContent: 'flex-end', marginRight: '8px' }}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Box>
                                  </ListItem>
                                )
                              })}
                            </List>
                          }
                        />
                      ) : (
                        <span />
                      )}
                      {selectedUser && selectedUser.type === 'ExternalUser' ? (
                        <>
                          {activationStatus === 'Deactivated' ? (
                            <CenteredDiv>
                              <SuccessButton
                                id='activateUserButton'
                                disabled={widgetsDisabled}
                                style={{
                                  marginTop: '10px',
                                  width: '200px'
                                }}
                                onClick={async () => {
                                  setWidgetsDisabled(true)
                                  await GatekeeperApi.activateUser(instance, accounts, selectedUser)
                                    .then(result => {
                                      enqueueSnackbar('User activated successfully', SnackbarVariants.SUCCESS)
                                      userRefetch()
                                    })
                                    .catch(e =>
                                      enqueueSnackbar(`Error activating user: ${e.response.message}`, SnackbarVariants.ERROR)
                                    )
                                  setWidgetsDisabled(false)
                                }}
                              >
                                Activate
                              </SuccessButton>
                            </CenteredDiv>
                          ) : (
                            <CenteredDiv>
                              <DeleteButton
                                id='deactivateUserButton'
                                disabled={widgetsDisabled}
                                style={{
                                  marginTop: '10px',
                                  width: '200px'
                                }}
                                onClick={async () => {
                                  setWidgetsDisabled(true)
                                  await GatekeeperApi.deactivateUser(instance, accounts, selectedUser)
                                    .then(result => {
                                      enqueueSnackbar('User deactivated successfully', SnackbarVariants.SUCCESS)
                                      userRefetch()
                                    })
                                    .catch(e =>
                                      enqueueSnackbar(`Error deactivating user: ${e.response.message}`, SnackbarVariants.ERROR)
                                    )
                                  setWidgetsDisabled(false)
                                }}
                              >
                                Deactivate
                              </DeleteButton>
                            </CenteredDiv>
                          )}
                          <HasAccess allowedRoles={['Gatekeeper Admin']} env={env}>
                            <CenteredDiv>
                              <DeleteButton
                                id='deleteUserButton'
                                disabled={widgetsDisabled}
                                style={{
                                  marginTop: '10px',
                                  width: '200px',
                                  color: 'white',
                                  backgroundColor: WtxColors.INNOVATION_RED
                                }}
                                onClick={() => setDeleteModelOpen(true)}
                              >
                                Delete
                              </DeleteButton>
                            </CenteredDiv>
                          </HasAccess>
                        </>
                      ) : null}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <div style={{ width: '100%', margin: '15px 0px', paddingLeft: '15px' }}>
                    <Tabs value={state ? URL_MAP[state] : 1} variant='scrollable' scrollButtons='auto'>
                      {['Roles', 'Notifications', 'History'].map(p => {
                        return (
                          <Tab
                            id={`${p}Tab`}
                            key={p}
                            sx={classes.tab}
                            label={p}
                            onClick={() => {
                              setState(p.toLowerCase())
                            }}
                          />
                        )
                      })}
                    </Tabs>
                    <Suspense
                      fallback={
                        <CenteredDiv>
                          <CircularProgress />
                        </CenteredDiv>
                      }
                    >
                      {
                        {
                          roles: (
                            <UserRolesTable
                              filter={filter}
                              roles={rolesList}
                              rolesLoading={isLoading}
                              widgetsDisabled={widgetsDisabled}
                              user={selectedUser}
                              renderSwitch={data => {
                                return (
                                  <Switch
                                    id={`${data.row.id}Switch`}
                                    checked={
                                      roleMap && roleMap[data.row.id]
                                        ? roleMap[data.row.id] === 'user' || roleMap[data.row.id] === 'group'
                                        : false
                                    }
                                    color={
                                      roleMap &&
                                      roleMap[data.row.id] &&
                                      (roleMap[data.row.id] === 'user' || roleMap[data.row.id] === 'group')
                                        ? 'primary'
                                        : 'secondary'
                                    }
                                    onChange={e => onSwitchChange(e, data.row.id)}
                                    disabled={widgetsDisabled || roleMap[data.row.id] === 'group'}
                                  />
                                )
                              }}
                            />
                          ),
                          notifications: (
                            <UserEmailNotificationsTable
                              user={userData}
                              widgetsDisabled={widgetsDisabled}
                              setWidgetsDisabled={setWidgetsDisabled}
                            />
                          ),
                          history: <ItemTimeline history={userData ? userData.history : []} />,
                          '': <div>Error</div>
                        }[state]
                      }
                    </Suspense>
                  </div>
                </Grid>
              </Grid>
            </>
          }
        />
      </SlideUpDialog>
    </>
  )
}
