import { Switch, Box } from '@mui/material'
import { PrimaryButton, PersistantFilterDiv } from '@wavetronix/common-components'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import DocumentFilterDrawer, { DEFAULT_DOCUMENTS_FILTER, filterDocuments } from '../../drawers/DocumentFilterDrawer'
import DocumentsApi from '../../../api/DocumentsApi'
import GroupsDocumentsTable from '../../gridComponents/GroupsDocumentsTable'
import { listHaveDifferences } from '../../../utils/arrayUtils.js'

export default function EditGroupDocuments({
  groupData,
  group,
  setGroup,
  instance,
  accounts,
  isUploading,
  setIsUploading,
  isReadOnly,
  updateGroupChangeType,
  generateListDiff,
  hasChanges,
  setHasChanges
}) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen
  const [documentsFilter, setDocumentsFilter] = useState(DEFAULT_DOCUMENTS_FILTER)

  const { data: documentsData, isLoading: documentsIsLoading } = useQuery({
    queryKey: ['documents'],
    queryFn: async () => await DocumentsApi.getDocuments(instance, accounts)
  })

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => await DocumentsApi.getCategories(instance, accounts)
  })

  const categoryMap = useMemo(() => {
    if (categories) {
      return categories.reduce((map, value) => {
        map[value.id] = value
        return map
      }, {})
    }
  }, [categories])

  useEffect(() => {
    if (group && groupData) {
      let hasDiff = listHaveDifferences(group.documents, groupData.documents)
      setHasChanges(hasDiff)
    }
  }, [group, groupData, setHasChanges])

  const filteredDocuments = useMemo(() => {
    if (documentsData && documentsFilter) {
      return filterDocuments(documentsFilter, documentsData)
    }
  }, [documentsData, documentsFilter])

  const documentsMap = useMemo(() => {
    if (documentsData) {
      return documentsData.reduce((map, obj) => {
        map[obj.id] = { ...obj }
        return map
      }, [])
    }
  }, [documentsData])

  async function onSwitchChange(e, doc) {
    setIsUploading(true)
    if (e.target.checked) {
      setGroup(g => ({ ...g, documents: [...g.documents, doc.id] }))
    } else {
      setGroup(g => ({ ...g, documents: g.documents.filter(id => id !== doc.id) }))
    }
    setIsUploading(false)
  }

  const generateNotes = ids => {
    let notes = []

    if (hasChanges) {
      generateListDiff(notes, 'documents', 'Documents', documentsMap, 'fileName')
    }

    return notes
  }

  return (
    <Box sx={{ display: 'block' }}>
      <PersistantFilterDiv
        defaultOpen={largeScreenFeaturesActive}
        resetFilter={() => setDocumentsFilter(DEFAULT_DOCUMENTS_FILTER)}
        drawer={<DocumentFilterDrawer setFilter={setDocumentsFilter} filter={documentsFilter} />}
        page={
          <>
            <Box sx={{ float: 'right', width: '100%' }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <PrimaryButton
                style={{ width: '200px', float: 'right', marginBottom: '15px' }}
                disabled={isUploading || isReadOnly || hasChanges ? !hasChanges : true}
                onClick={async () => {
                  setIsUploading(true)
                  await updateGroupChangeType('documents', group.documents, generateNotes(group.documents))
                  setIsUploading(false)
                }}
              >
                Save
              </PrimaryButton>
            </Box>
            <Box sx={{ width: '100%' }}>
              <GroupsDocumentsTable
                categoryMap={categoryMap}
                documents={filteredDocuments ? filteredDocuments : []}
                isLoading={documentsIsLoading}
                overridesComparator={(a, b) => {
                  if (!documentsMap) return 0
                  if (documentsMap[a] === true && documentsMap[b] === false) {
                    return -1
                  } else if (documentsMap[a] === false && documentsMap[b] === true) {
                    return 1
                  } else {
                    return 0
                  }
                }}
                renderSwitch={doc => (
                  <Switch
                    checked={group.documents ? group.documents.includes(doc.id) : false}
                    color={group.documents && group.documents.includes(doc.id) ? 'primary' : 'secondary'}
                    onChange={e => onSwitchChange(e, doc)}
                    disabled={isUploading || isReadOnly}
                  />
                )}
              />
            </Box>
          </>
        }
      />
    </Box>
  )
}
