const fr = {
  givenName: 'Prénom',
  surname: 'Surname',
  company: 'Compagnie',
  email: 'E-mail',
  filter: 'Filtre',
  groups: 'Groupes',
  clear: 'Dégager',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  region: 'Région',
  name: 'Nom',
  apply: 'Appliquer',
  cancel: 'Annuler',
  close: 'Close',
  back: 'Back',
  manageUser: "Gérer l'utilisateur",
  save: 'Sauvegarder',
  'My Software': 'Mon Logiciel',
  'My Licenses': 'Mes Licences',
  'My Documents': 'Mes Documents',
  noAccess: "Vous n'avez actuellement accès à aucun site. Veuillez contacter un représentant de l'entreprise.",
  snackbar: {
    saving: 'Salvateur...',
    savedRoles: 'Rôle mis à jour enregistré avec succès',
    errorSaving: "Erreur lors de l'enregistrement du rôle"
  },
  description: 'Description',
  accessLevel: "Niveau d'Accès",
  associatedProducts: 'Produits Associés',
  documentType: 'Type Document',
  products: 'Produits',
  nexusPortal: 'PORTAIL',
  nexusSignIn: 'Connectez-vous à Nexus',
  signIn: "S'identifier",
  invalidAccount: 'Erreur: Problème lors de la validation du compte pour cet environnement'
}
export { fr }
