import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class GatekeeperApi {
  //USERS
  getUsers = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getUsersInjected = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/injectroles`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => {
        return res.data
      })
  }

  getUsersIncludeDeactivated = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/includedeactivated`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getUserById = async (msalInstance, accounts, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getMe = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  deleteExternalUserById = async (msalInstance, accounts, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .delete(`${env.urls.gatekeeperURL}/users/deleteuser/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  deactivateUser = async (msalInstance, accounts, user) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .post(`${env.urls.gatekeeperURL}/users/deactivateuser`, user, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  activateUser = async (msalInstance, accounts, user) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .post(`${env.urls.gatekeeperURL}/users/activateuser`, user, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getUserByIdNoGroups = async (msalInstance, accounts, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/${userId}/withoutGroups`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  updateUserAccess = async (msalInstance, accounts, id, accessLevel) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .put(`${env.urls.gatekeeperURL}/users/docaccess/${id}?accessLevel=${accessLevel}`, null, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  addRoleToUser = async (msalInstance, accounts, id, role) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.post(
      `${env.urls.gatekeeperURL}/users/${id}/roles/${role}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      }
    )
  }

  removeRoleFromUser = async (msalInstance, accounts, id, role) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.delete(`${env.urls.gatekeeperURL}/users/${id}/roles/${role}`, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  addTagToUser = async (msalInstance, accounts, id, tag) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.post(
      `${env.urls.gatekeeperURL}/users/${id}/tags/add/${tag}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      }
    )
  }

  removeTagFromUser = async (msalInstance, accounts, id, tag) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.delete(`${env.urls.gatekeeperURL}/users/${id}/tags/remove/${tag}`, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  addGroupToUser = async (msalInstance, accounts, id, group) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.post(
      `${env.urls.gatekeeperURL}/users/${id}/groups/${group}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      }
    )
  }

  removeGroupFromUser = async (msalInstance, accounts, id, group) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.delete(`${env.urls.gatekeeperURL}/users/${id}/groups/${group}`, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  getUserGroups = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/users/${id}/groups`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  //ROLES
  getRoles = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/api/roles`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  updateRoleInfo = async (msalInstance, accounts, role) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.put(`${env.urls.gatekeeperURL}/api/roles`, role, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  createRoleInfo = async (msalInstance, accounts, role) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.put(`${env.urls.gatekeeperURL}/api/roles/create`, role, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  //GROUPS
  getGroups = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/api/groups`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getGroup = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/api/groups/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  createGroup = async (msalInstance, accounts, group) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.post(`${env.urls.gatekeeperURL}/api/groups`, group, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  updateGroup = async (msalInstance, accounts, group) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.put(`${env.urls.gatekeeperURL}/api/groups`, group, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  deleteGroup = async (msalInstance, accounts, id) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.delete(`${env.urls.gatekeeperURL}/api/groups/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  changeGroupByType = async (msalInstance, accounts, type, groupId, groupUpdateInfo) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.put(`${env.urls.gatekeeperURL}/api/groups/${groupId}/${type}`, groupUpdateInfo, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 30000
    })
  }

  //Regions
  getRegions = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.gatekeeperURL}/region`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  addUserToRegion = async (msalInstance, accounts, user, region) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.put(
      `${env.urls.gatekeeperURL}/region/${region}/addemail/${user}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      }
    )
  }

  removeUserFromRegion = async (msalInstance, accounts, user, region) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios.put(
      `${env.urls.gatekeeperURL}/region/${region}/removeemail/${user}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      }
    )
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new GatekeeperApi()
  }
  return instance
}

export default getInstance()
